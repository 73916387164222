import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'app/components';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask, useMoneyFormatter, useToastCatcher } from 'app/utils';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import XLSX from 'xlsx';
import { WalletTxList } from '../components';
// import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  card: {
    marginBottom: theme.spacing(1),
    minWidth: 300,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      '&:first-child': {
        marginRight: theme.spacing(2),
      },
    },
  },
  sameRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  addCard: {
    alignContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: 102,
    justifyContent: 'space-evenly',
    padding: theme.spacing(2),
    marginTop: 20,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
      // boxShadow: "0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);",
      transform: 'scale(1.1)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  icon: {
    alignSelf: 'center',
  },
}));

let mounted = false;
const Billing = (props) => {
  const { children, className, staticContext, routeType, ...rest } = props;
  const classes = useStyles();
  const access = useSelector(({ profile }) => profile.access);
  const [loading, setLoading] = useState(false);
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'loadWallet' });
  const [runLoadWalletTx] = useAsyncTask('walletTxExport');
  const [wallet, setWallet] = useState();
  const [reloadflag, setReloadflag] = useState(false);
  const formatter = useMoneyFormatter({ currency: 'TXC', showCurrency: true });
  const [filter, setFilter] = useState();
  // const router = useRouter();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) {
      setWallet(null);
      loadWallet();
    }
    // eslint-disable-next-line
  }, [access]);

  const loadWallet = () => {
    setLoading(true);
    errorCatcher(async () => {
      const { wallet } = await API.Wallet.get_main_wallet();
      if (mounted) {
        if (!wallet) toaster('No wallet found');
        else setWallet(wallet);
      }
      setLoading(false);
      setReloadflag(true);
    });
  };

  const onExport = () => {
    runLoadWalletTx(async () => {
      const { models } = routeType !== 'admin' ? await API.Wallet.list_wallet_tx({ ...filter, nolimit: 'true' }, wallet.id) : await API.Admin.list_wallet_tx({ ...filter, nolimit: 'true' });
      const data = sort_export_array(models);
      const workbook = {
        SheetNames: ['Credit usage'],
        Sheets: {
          'Credit usage': XLSX.utils.aoa_to_sheet(data),
        },
      };
      return XLSX.writeFile(workbook, routeType !== 'admin' ? `${wallet.reference}_${moment().format('YYYY-MM-DD-HH:mm:ss')}.xlsx` : `all_wallets_${moment().format('YYYY-MM-DD-HH:mm:ss')}.xlsx`);
    });
  };
  const getSourceData = (model) => {
    switch (model.type) {
      case 'pay_screen':
      case 'pay_report':
        return model.owner_screen ? `${model.owner_screen.screen_request?.params?.name} (${model.owner_screen.reference})` : '';
      case 'pay_verification':
        return `${model.owner_verification?.reference}`;
      case 'pay_cached_article_retrieval':
      case 'refund_cached_article_retrieval':
        return `${model.owner_cached_article_retrieval?.screen_profile?.screen_result?.name} (${model.owner_cached_article_retrieval?.reference})`;
      default:
        return '';
    }
  };
  const sort_export_array = (models) => {
    let export_arr =
      routeType !== 'admin'
        ? [['Transaction ID', 'Transaction Time', 'Type', 'Comment', 'Source', 'Amount', 'Running amount']]
        : [['Account ID', 'Wallet ID', 'Transaction ID', 'Transaction Time', 'Type', 'Comment', 'Source', 'Amount', 'Running amount']];
    if (routeType !== 'admin') {
      models.forEach((tx, index) => {
        export_arr.push([tx.reference, moment(tx.timestamp).format('DD-MMM-YYYY HH:mm:ss'), snakeToTitle(tx.type), tx.comments || '', getSourceData(tx), formatter(tx.amount), formatter(tx.post_balance)]);
      });
    } else {
      models.forEach((tx, index) => {
        export_arr.push([
          tx.wallet.entity.account.reference,
          tx.wallet.reference,
          tx.reference,
          moment(tx.timestamp).format('DD-MMM-YYYY HH:mm:ss'),
          snakeToTitle(tx.type),
          tx.comments || '',
          getSourceData(tx),
          formatter(tx.amount),
          formatter(tx.post_balance),
        ]);
      });
    }
    return export_arr;
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Title
        prefix={'Credit usage'}
        floatButton={
          !!wallet && {
            onClick: onExport,
            text: 'Export',
          }
        }
      />
      {/* {wallet && (<AllBalance walletId={wallet.id} />)} */}
      {wallet && (
        <div className={classes.sameRow}>
          {/* <WalletCard className={classes.card} wallet={wallet} /> */}
          {/* <Card onClick={() => router.history.push("/topup")} className={classes.addCard}>
            <CardContent className={classes.content}>
              <AddIcon className={classes.icon} fontSize="large" />
              <Typography>Add Credit</Typography>
            </CardContent>
          </Card> */}
        </div>
      )}

      {wallet && !loading && <WalletTxList setExportFilter={setFilter} reloadflag={reloadflag} setReloadflag={() => setReloadflag(false)} userType={routeType} routeType={routeType} wallet={wallet} />}
    </Box>
  );
};

export default Billing;
